import { atom } from "recoil";

/**
 * Store applicatif afin de centraliser les données de l'application web
 * qui peuvent être utilisées à plusieurs endroits dans l'application.
 * Cela permet d'éviter les bugs et permet de réduire les appels réseaux
 * inutiles.
 * OPTIMIZED
 */

export const activitiesStore = atom({
  key: "activitiesStore",
  default: null,
});

export const categoriesStore = atom({
  key: "categoriesStore",
  default: null,
});

export const cartsStore = atom({
  key: "cartsStore",
  default: null,
});

export const filterStore = atom({
  key: "filterStore",
  default: null,
});

export const languageStore = atom({
  key: "languageStore",
  default: null,
});

export const daysStore = atom({
  key: "daysStore",
  default: null,
});

export const openingsStore = atom({
  key: "openingsStore",
  default: null,
});

import { useTranslation } from "react-i18next";
import LoadingIIL from "./LoadingIIL";

/**
 * Loading général de l'application. Utilisé
 * au chargement de l'application ou des pages
 * en lazy loading
 * @returns JSX
 * OPTIMIZED
 */
function Loading() {
  const { t } = useTranslation();

  // Labels of the component
  const LOADING = t("loading");

  return (
    <div className="Loading">
      <LoadingIIL />
      <span>{LOADING}</span>
    </div>
  );
}

export default Loading;

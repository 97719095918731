import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  activitiesStore,
  cartsStore,
  categoriesStore,
  daysStore,
  openingsStore,
} from "../store";
import get_categories from "../services/get-categories";
import get_activities from "../services/get-activities";
// import filter_published_activities from "../tools/filter-published-activities";
import { filter_children_activities } from "../tools/filter-children-activities";
import get_carts_of_parent from "../services/get-carts-of-parent";
import get_days from "../services/get-days";
import get_current_or_next_opening from "../services/get-current-or-next-opening";

/**
 * Import des données qui seront exploitées par l'utilisateur
 * Il doit être présent, et avoir des enfants, sinon, rien ne sera retourné
 * @param {Object} user
 * @returns Object
 * OPTIMIZED
 */
function useFetchInitialDatas(user) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const setCategories = useSetRecoilState(categoriesStore);
  const setActivities = useSetRecoilState(activitiesStore);
  const setCarts = useSetRecoilState(cartsStore);
  const setDays = useSetRecoilState(daysStore);
  const setOpenings = useSetRecoilState(openingsStore);

  useEffect(() => {
    async function fetchDatas() {
      try {
        if (user) {
          // Récupération des catégories
          const categories = await get_categories();
          setCategories(categories);

          // Récupération des jours
          const days = await get_days();
          setDays(days);

          // Récupération des dates d'ouvertures
          const openings = await get_current_or_next_opening();
          setOpenings(openings);

          // Récupération de toutes les activités
          const activities = await get_activities();

          // Filtrage des activités publiées
          // const published_activities = filter_published_activities(activities);

          // Sans enfant, l'utilisateur n'a rien a faire là
          if (user.children && categories && openings) {
            let children_activities = [];
            // Filtrage des activités en fonction des enfants de l'utilisateur
            children_activities = await filter_children_activities(
              user.children,
              activities,
              openings
            );

            setActivities(children_activities);

            // Récupération du panier de l'utilisateur
            const carts = await get_carts_of_parent(user.id);
            setCarts(carts);
          } else {
            setActivities([]);
            setCarts([]);
          }
        }

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    fetchDatas();
  }, [user, setCategories, setActivities, setCarts, setDays, setOpenings]);

  return { loading, error };
}

export default useFetchInitialDatas;

function LoadingIIL() {
  return (
    <div className="LoadingIIL">
      <div className="circle">
        <div className="iil-box">
          <div className="small-i">
            <div className="point"></div>
            <div className="line"></div>
          </div>
          <div className="big-i">
            <div className="point"></div>
            <div className="line"></div>
          </div>
          <div className="big-l">
            <div className="line"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingIIL;

import axios from "axios";

async function get_current_user() {
  try {
    const API_URL = `${process.env.REACT_APP_API_AES_URL}/v1/current-user`;

    const response = await axios.post(
      API_URL,
      {},
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error) {
    if (error.response.status === 401) {
      return null;
    }
    throw new Error(error);
  }
}

export default get_current_user;

import axios from "axios";

/**
 * Retourne les dates d'ouvertures courante ou à venir
 * @returns Array
 * OPTIMIZED
 */
async function get_current_or_next_opening() {
  try {
    const API_URL = `${process.env.REACT_APP_API_AES_URL}/v1/openings/schedule/current-or-next`;

    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.status === 200) {
      return response.data;
    }

    return null;
  } catch (error) {
    throw new Error(error);
  }
}

export default get_current_or_next_opening;

import axios from "axios";

/**
 * Retourne si la période d'inscription est ouverte
 * @returns Boolean
 * OPTIMIZED
 */
async function get_is_open_registration_period() {
  try {
    const API_URL = `${process.env.REACT_APP_API_AES_URL}/v1/openings/schedule/is-open`;

    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.status === 200) {
      const datas = response.data;

      return datas.is_open;
    }

    return false;
  } catch (error) {
    throw new Error(error);
  }
}

export default get_is_open_registration_period;

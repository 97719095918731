import { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

/**
 * Route protégée par un guard d'authentification
 * En cas de non connexion, l'utilisateur est redirigé
 * vers une page d'avertissement
 * OPTIMIZED
 */
function ProtectedRoute({ children }) {
  const information = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!information) return navigate("/sign-in");
  });

  return information && children;
}

export default ProtectedRoute;

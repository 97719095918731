import { createBrowserRouter, redirect } from "react-router-dom";
import { lazy } from "react";
import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import SignInPage from "./pages/SignInPage";
import ProtectedRoute from "./components/utils/ProtectedRoute";

const FavoritesPage = lazy(() => import("./pages/FavoritesPage"));
const CartPage = lazy(() => import("./pages/CartPage"));
const OrdersPage = lazy(() => import("./pages/OrdersPage"));
const ActivitiesPage = lazy(() => import("./pages/ActivitiesPage"));
const AllActivities = lazy(() =>
  import("./components/activities/AllActivities")
);
const CategorizedActivities = lazy(() =>
  import("./components/activities/CategorizedActivities")
);
const ActivityDetailsPage = lazy(() => import("./pages/ActivityDetailsPage"));

/**
 * Router de l'application client
 * OPTIMIZED
 */
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: () => redirect("/activities"),
        },
        {
          path: "favorites",
          element: (
            <ProtectedRoute>
              <FavoritesPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "cart",
          element: (
            <ProtectedRoute>
              <CartPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "orders",
          element: (
            <ProtectedRoute>
              <OrdersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "activities",
          element: (
            <ProtectedRoute>
              <ActivitiesPage />
            </ProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: <AllActivities />,
            },
            {
              path: ":categorySlug/:activitySlug",
              element: <ActivityDetailsPage />,
            },
            {
              path: ":categorySlug",
              element: <CategorizedActivities />,
            },
          ],
        },
      ],
    },
    { path: "sign-in", element: <SignInPage /> },
  ],
  {
    basename: process.env.PUBLIC_URL,
  }
);

export default router;

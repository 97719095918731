import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoIIL from "../components/footer/logo/LogoIIL";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

/**
 * Page de fallback si l'utilisateur n'est pas connecté
 * @returns JSX
 * OPTIMIZED
 */
function SignInPage() {
  const { t } = useTranslation();

  const ENT_URL = process.env.REACT_APP_ENT_URL;

  // Labels of the component
  const TITLE = t("sign_in.title");
  const DESCRIPTION = t("sign_in.description");
  const SIGNIN = t("sign_in.sign_in");

  return (
    <div className="ErrorPage bg-danger">
      <section>
        <LogoIIL />
        <h1>{TITLE}</h1>
        <p>{DESCRIPTION}</p>
        <div>
          <a
            className="button-link button-white"
            href={ENT_URL}
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faLock} />
            {SIGNIN}
          </a>
        </div>
      </section>
    </div>
  );
}

export default SignInPage;

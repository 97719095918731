import Cookies from "js-cookie";

/**
 * Contrôle la présence du cookie pour la bannière de bienvenue
 * dans le navigateur client
 * @returns Boolean
 * OPTIMIZED
 */
export function check_welcome_cookie() {
  const welcome_cookie = Cookies.get("welcome_aes");

  if (welcome_cookie) {
    return true;
  }

  return false;
}

/**
 * Construction du cookie pour la bannière de bienvenue
 * dans le navigateur de l'utilisateur, la date d'expiration du cookie
 * est la fin de l'année scolaire en cours
 * @returns Cookie
 * OPTIMIZED
 */
export function build_welcome_cookie() {
  const today = new Date();
  const end_of_school_year = new Date();
  end_of_school_year.setDate(30);
  end_of_school_year.setMonth(5);

  if (today.getMonth() > 6) {
    end_of_school_year.setFullYear(end_of_school_year.getFullYear() + 1);
    // On est la fin d'année civile on met sur la fin d'année scolaire (l'année civile suivante)
  } else {
    end_of_school_year.setFullYear(end_of_school_year.getFullYear());
    // On est sur la début d'année civile, on met sur la fin d'année scolaire
  }

  // Cookie de bienvenue non présent, on le crée
  const time_end_of_year =
    (end_of_school_year.getTime() - today.getTime()) / 1000;

  document.cookie = `welcome_aes=accepted_until_the_end_of_school_year;max-age=${time_end_of_year};domain=.iil.ch;path=/;sameSite=Lax`;
}

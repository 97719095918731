import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

/**
 * Internationalisation de l'application.
 * Les fichiers de traduction sont dans le dossier /public/locales
 * PS : Possibilité d'externaliser la traduction avec LOCIZE qui est
 * compatible avec le plugin react-i18next
 * OPTIMIZED
 */
i18n
  .use(LanguageDetector)
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["navigator", "htmlTag"],
    },
    fallbackLng: "en",
    load: "languageOnly",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  })
  .then(() => {
    const shortCode = i18n.resolvedLanguage;
    i18n.changeLanguage(shortCode);
  })
  .catch((err) => console.error(err));

i18n.on("languageChanged", () => {
  const shortCode = i18n.resolvedLanguage;
  document.documentElement.lang = shortCode;
});

export default i18n;

import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

/**
 * Hook de gestion d'erreur général. Formate les erreurs pour la page d'erreur
 * @returns Object
 * OPTIMIZED
 */
function useErrorHandler() {
  const error = useRouteError();
  const { t } = useTranslation();

  let title = "";
  let description = "";

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        title = t("errors.404.title");
        description = t("errors.404.description");
        break;

      default:
        title = t("errors.400.title");
        description = t("errors.400.description");
    }
  } else {
    title = t("errors.500.title");
    description = t("errors.500.description");
  }

  return { title, description };
}

export default useErrorHandler;

import useFetchInitialConfig from "./hooks/useFetchInitialConfig";
import Loading from "./components/utils/Loading";
import Alert from "./components/errors/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { OpenContext } from "./contexts/OpenContext";
import { AuthContext } from "./contexts/AuthContext";
import { Suspense } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import useFetchInitialDatas from "./hooks/useFetchInitialDatas";
import WelcomeAES from "./components/utils/WelcomeAES";

/**
 * Composant racine
 * @returns JSX
 * OPTIMIZED
 */
function App() {
  const initialConfig = useFetchInitialConfig();
  const initialDatas = useFetchInitialDatas(initialConfig.user);

  // Cas du chargement
  if (initialConfig.loading || initialDatas.loading) return <Loading />;

  // Cas de l'erreur
  if (initialConfig.error || initialDatas.error) {
    return (
      <Alert
        cssClass="danger"
        icon={<FontAwesomeIcon icon={faSadTear} />}
      ></Alert>
    );
  }

  // Cas OK
  return (
    <OpenContext.Provider value={initialConfig.open}>
      <AuthContext.Provider value={initialConfig.user}>
        <Suspense fallback={<Loading />}>
          <div className="App">
            <WelcomeAES user={initialConfig.user} />
            <ScrollRestoration />
            <Outlet />
          </div>
        </Suspense>
      </AuthContext.Provider>
    </OpenContext.Provider>
  );
}

export default App;

import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

/**
 * Bloc d'affichage d'avertissement
 * @param {Object} props
 * @returns JSX
 * OPTIMIZED
 */
function Alert({ cssClass, title, children, icon }) {
  const { t } = useTranslation();

  // Labels of the component
  const OOPS = t("errors.oops");
  const ERROR = t("errors.details");

  const CSS = cssClass ? cssClass : "danger";

  return (
    <div className={`Alert ${CSS}`}>
      <div className="icon">
        {icon ? icon : <FontAwesomeIcon icon={faCircleInfo} />}
      </div>
      <div className="content">
        <h2>{title ? title : OOPS}</h2>
        {children ? children : <p>{ERROR}</p>}
      </div>
    </div>
  );
}

export default Alert;

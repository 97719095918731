import { useEffect, useState } from "react";
import get_is_open_registration_period from "../services/get-is-open-registration-period";
import get_current_user from "../services/get-current-user";

/**
 * Import du contexte de l'application (ouverture des inscription et utilisateur)
 * Si pas d'utilisateur = Page de warning, pas d'ouverture, bandeau en tête de page
 * @returns Object
 * OPTIMIZED
 */
function useFetchInitialConfig() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchDatas() {
      try {
        // Récupération du user connecté
        const user = await get_current_user();

        setUser(user);

        // Est-ce la période des inscription ou pas ?
        const is_open = await get_is_open_registration_period();
        setOpen(is_open);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    fetchDatas();
  }, []);

  return { loading, error, open, user };
}

export default useFetchInitialConfig;

import { useTranslation } from "react-i18next";
import logo from "../../../images/iil-logo-white-768.png";

/**
 * Logo de l'Institut
 * @returns JSX
 * OPTIMIZED
 */
function LogoIIL() {
  const { t } = useTranslation();
  const IIL_URL = process.env.REACT_APP_IIL_URL;

  // Labels of the components
  const ALT = t("footer.logo.alt");

  return (
    <figure>
      <a href={IIL_URL} target="_blank" rel="noreferrer">
        <img src={logo} width="180px" height="auto" alt={ALT} loading="lazy" />
      </a>
    </figure>
  );
}

export default LogoIIL;

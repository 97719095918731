// import is_free_on_timetable from "./has-already-a-lesson";

/**
 * Retourne les activités filtrées en fonction des enfants (âge et niveaux scolaires)
 * ATTENTION : les activités sont celles publiées et non publiées. Les activités en
 * entrées doivent être préalablement filtrées si besoin
 * @param {Array} children
 * @param {Array} activities
 * @returns Array
 * OPTIMIZED
 */
export async function filter_children_activities(
  children,
  activities,
  openingDate
) {
  try {
    const activities_filtered = [];

    for (const activity of activities) {
      const children_of_activity = [];
      let this_activity_is_allowed = false;

      for (const child of children) {
        let age_is_ok_for_this_child = false;
        let level_is_ok_for_this_child = false;
        let edt_is_ok_for_child = false;

        // Check de l'âge
        age_is_ok_for_this_child = await check_child_age_vs_activity_ages(
          child,
          activity,
          openingDate
        );

        // Check du niveau scolaire de l'enfant
        level_is_ok_for_this_child = check_child_level_vs_activity_levels(
          child,
          activity
        );

        // Check des EDT
        // let child_timetable = child.timetable ? child.timetable : null;
        // edt_is_ok_for_child = is_free_on_timetable(activity, child_timetable);
        edt_is_ok_for_child = true;

        // Si les trois conditions sont remplis, l'enfant est mis dans l'activité
        if (
          age_is_ok_for_this_child &&
          level_is_ok_for_this_child &&
          edt_is_ok_for_child
        ) {
          children_of_activity.push(child.id);
          this_activity_is_allowed = true;
        }
      }

      activity.children_of_activity = children_of_activity;

      if (this_activity_is_allowed) activities_filtered.push(activity);
    }

    return activities_filtered;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Check si l'enfant à l'âge pour l'activité
 * @param {Object} child
 * @param {Object} activity
 * @returns Boolean
 * OPTIMIZED
 */
export async function check_child_age_vs_activity_ages(
  child,
  activity,
  openingDate
) {
  const date_of_birth = get_date_of_birth(child);
  const age_of_child = await get_age_of_child_for_registration(
    date_of_birth,
    openingDate
  );

  for (const oneAge of activity.ages) {
    if (oneAge.age === age_of_child) {
      return true;
    }
  }

  return false;
}

/**
 * Check si l'enfant à le niveau scolaire pour l'activité
 * @param {Object} child
 * @param {Object} activity
 * @returns Boolean
 * OPTIMIZED
 */
export function check_child_level_vs_activity_levels(child, activity) {
  const level_of_child = child.level ? child.level.id : "unknown";

  for (const oneLevel of activity.levels) {
    if (oneLevel.id === level_of_child) {
      return true;
    }
  }

  return false;
}

/**
 * Retourne la date de naissance de l'enfant
 * @param {Object} child
 * @returns Date | null
 * OPTIMIZED
 */
function get_date_of_birth(child) {
  const date_of_birth = child.date_of_birth
    ? new Date(child.date_of_birth)
    : null;

  return date_of_birth;
}

/**
 * Retourne l'âge d'un enfant par rapport à la fin des inscriptions
 * @param {Date} date_of_birth
 * @returns Integer
 * OPTIMIZED
 */
async function get_age_of_child_for_registration(date_of_birth, openingDate) {
  let end_date_of_registration = null;

  end_date_of_registration = openingDate.end_date
    ? new Date(openingDate.end_date)
    : new Date();

  let age_of_child = end_date_of_registration - date_of_birth;
  age_of_child = new Date(age_of_child);
  age_of_child = Math.abs(age_of_child.getUTCFullYear() - 1970);

  return age_of_child;
}

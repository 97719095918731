import Swal from "sweetalert2";

/**
 * Bannière de bienvenue sur le site, est affichée si le cookie n'est pas
 * présent dans le navigateur. Une action de callback peut être faite en cas
 * de confirmation de la part de l'utilisateur
 * @param {String} welcome
 * @param {Object} user
 * @param {String} html
 * @param {String} yes
 * @param {Function} callback
 * OPTIMIZED
 */
function modal_welcome(welcome, user, html, yes, callback) {
  Swal.fire({
    title: `${welcome} ${user.first_name} !`,
    html: html,
    width: "50%",
    confirmButtonText: yes,
    buttonsStyling: false,
    customClass: {
      confirmButton: "button-primary",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

export default modal_welcome;

import { useTranslation } from "react-i18next";
import modal_welcome from "../../tools/modal-welcome";
import {
  build_welcome_cookie,
  check_welcome_cookie,
} from "../../services/check-welcome-cookie";
import { useEffect, useState } from "react";

/**
 * Bannière d'accueil pour présenter l'application, utilise un Cookie dans
 * le navigateur du client
 * @param {Object} user
 * @returns JS Modal | null
 * OPTIMIZED
 */
function WelcomeAES({ user }) {
  const { t } = useTranslation();
  const [showWelcome, setShowWelcome] = useState(false);
  const [loading, setLoading] = useState(true);
  const [children, setChildren] = useState([]);

  useEffect(() => {
    async function load() {
      if (user) {
        if (user.children) {
          const children_tab = [];
          for (const child of user.children) {
            children_tab.push(child.first_name);
          }
          setChildren(children_tab.join(" & "));
        }

        const is_cookie = check_welcome_cookie();
        if (!is_cookie) {
          setShowWelcome(true);
        } else {
          setShowWelcome(false);
        }
        setLoading(false);
      }
    }
    load();
  }, [user]);

  function build_cookie() {
    build_welcome_cookie();
    setShowWelcome(false);
  }

  // // Dans le cas où il n'y a pas d'enfant, on arrête le chargement de la bannière
  // if (user && children.length === 0) return null;

  // Labels of the component
  const welcome = t("welcome.title");
  const yes = t("welcome.confirm");
  const text_1 = t("welcome.text_1");
  const text_2 = t("welcome.text_2");
  const text_3 = t("welcome.text_3");
  const warning = t("welcome.warning");
  const text_4 = t("welcome.text_4");
  const text_6 = t("welcome.text_6");
  const text_7 = t("welcome.text_7");
  const text_8 = t("welcome.text_8");
  const text_9 = t("welcome.text_9");
  const text_10 = t("welcome.text_10");
  const text_11 = t("welcome.text_11");
  const text_12 = t("welcome.text_12");
  const text_13 = t("welcome.text_13");
  const htmlText = `<div>
    <p>${text_1}</p>
    <p>${text_2}</p>
    <pre>${children}</pre>
    <p>${text_3}</p>
    <p style="font-weight:bold">
      <span style="text-decoration: underline">${warning}</span> : ${text_4}
    </p>
    <p>
    <ul style="list-style:none;">
      <li style="font-weight:bold" class="text-info">${text_6}</li>
      <li style="font-weight:bold" class="text-warning">${text_7} ${text_8}</li>
    </ul>
    </p>
    <p>${text_9}</p>
    <p>
      ${text_10}
      <br/>
      <span style="font-weight:bold">${text_13}</span>
    </p>
    <p>${text_11}</p>
    <p>${text_12}</p>
  </div>`;

  return !loading && showWelcome
    ? modal_welcome(welcome, user, htmlText, yes, build_cookie)
    : null;
}

export default WelcomeAES;

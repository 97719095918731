import axios from "axios";

/**
 * Retourne toutes les activités des AES (publiées ou non publiées)
 * @returns Array
 * OPTIMIZED
 */
async function get_activities() {
  try {
    const API_URL = `${process.env.REACT_APP_API_AES_URL}/v1/activities?published=true`;
    //const API_URL = `${process.env.REACT_APP_API_AES_URL}/v1/activities`;

    const response = await axios.get(API_URL, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.status === 200) {
      const datas = response.data;
      return Array.isArray(datas) ? datas : [datas];
    }

    return [];
  } catch (error) {
    throw new Error(error);
  }
}

export default get_activities;

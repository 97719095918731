import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import router from "./routes";
import Loading from "./components/utils/Loading";

import "./index.scss";
import "./translations/i18n";

/**
 * Point d'entrée de l'application client
 * OPTIMIZED
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </RecoilRoot>
  </React.StrictMode>
);

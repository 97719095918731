import useErrorHandler from "../hooks/useErrorHandler";
import { Link } from "react-router-dom";
import LogoIIL from "../components/footer/logo/LogoIIL";
import { useTranslation } from "react-i18next";

/**
 * Page globale d'erreur
 * @returns JSX
 * OPTIMIZED
 */
function ErrorPage() {
  const error = useErrorHandler();
  const { t } = useTranslation();

  // Labels of the components
  const BACK = t("errors.back");

  return (
    <div className="ErrorPage">
      <section>
        <LogoIIL />
        <h1>{error.title}</h1>
        <p>{error.description}</p>
        <div>
          <Link className="button-link button-white" to="/">
            {BACK}
          </Link>
        </div>
      </section>
    </div>
  );
}

export default ErrorPage;
